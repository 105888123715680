import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  useDisclosure,
} from "@chakra-ui/react";
import NextImage from "next/image";

import { mdiAccount, mdiExitToApp, mdiMenu } from "@mdi/js";
import { useTranslation } from "next-i18next";
import { Link as ChakraLink } from "@chakra-ui/next-js";
import { useContext, useRef, useState } from "react";
import { axiosClient } from "apis/rest/axios-client";
import { MdIcon } from "@/common/icons/MdIcon";
import { useSSRBreakpointValue } from "@/common/hooks/useSSRBreakpointValue";

import AkwadLogoDark from "@/modules/international/college/assets/images/logo-dark-named-354x105.png";
import { urls } from "@/reverse/urls";
import { useGlobalServerValues } from "@/common/components/GlobalServerValuesProvider";
import { BrandLogoMapped } from "@/modules/international/common/constants";
import { InternationalSiteContext } from "@/modules/international/common/contexts/InternationalSiteContext";

export const InternationalCollegeMenu = () => {
  const isDesktop = useSSRBreakpointValue([false, null, null, true]);
  const [isLoading, setIsLoading] = useState(false);
  const { t, i18n } = useTranslation(["common"]);
  const dir = i18n.dir();
  const {
    currentUser: { is_authenticated, first_name, last_name },
  } = useGlobalServerValues();
  const {
    site: { domain },
  } = useContext(InternationalSiteContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef();

  const logoutHandler = async () => {
    try {
      setIsLoading(true);
      const { data } = await axiosClient.post<{ success: boolean }>(
        urls["international:international_auth:logout_api"](),
      );
      if (data.success) window.location.reload();
    } catch (error) {
      throw new Error(error);
    } finally {
      setIsLoading(false);
      onClose();
    }
  };

  return (
    <>
      <HStack pos="relative" justify={["space-between", null, null, "flex-start"]}>
        {isDesktop ? (
          <HStack w="full" justify="space-between" align="center" spacing={[8, null, null, 8, 16]}>
            <HStack spacing={12}>
              <Box as={ChakraLink} href={urls["international:international_college:landing_page"]()}>
                <NextImage src={BrandLogoMapped[domain]} width={200} alt="Akwad" style={{ objectFit: "cover" }} />
              </Box>
            </HStack>
            {is_authenticated ? (
              <Menu>
                <MenuButton
                  as={Button}
                  aria-label="Options"
                  leftIcon={<MdIcon color="brand.400" icon={mdiAccount} boxSize={6} />}
                  variant="outline"
                >
                  {`${first_name} ${last_name}`}
                </MenuButton>
                <MenuList>
                  <MenuItem icon={<MdIcon color="brand.400" icon={mdiExitToApp} boxSize={6} />} onClick={onOpen}>
                    {t("logout")}
                  </MenuItem>
                </MenuList>
              </Menu>
            ) : (
              <Button
                as={ChakraLink}
                href={urls["international:international_auth:login_page"]()}
                colorScheme="brand"
                variant="outline"
                maxW="200px"
                px={20}
              >
                {t("login")}
              </Button>
            )}
          </HStack>
        ) : (
          <>
            <NextImage src={AkwadLogoDark} alt="Akwad" width={120} style={{ objectFit: "cover" }} />
            <Menu>
              <MenuButton
                colorScheme="gray"
                as={IconButton}
                aria-label="Options"
                icon={<MdIcon icon={mdiMenu} />}
                fontSize={32}
                color="gray.100"
                _hover={{ color: "gray.400", bg: "whiteAlpha.500" }}
                _active={{ color: "gray.400", bg: "whiteAlpha.600" }}
                variant="ghost"
              />
              <MenuList w="100vw" fontSize="xl" zIndex={200} sx={{ "> a": { color: "gray.300" } }}>
                {is_authenticated ? (
                  <>
                    <MenuDivider />
                    <MenuGroup title={t("profile")}>
                      <MenuItem icon={<MdIcon color="brand.400" icon={mdiExitToApp} boxSize={6} />} onClick={onOpen}>
                        {t("logout")}
                      </MenuItem>
                    </MenuGroup>
                  </>
                ) : (
                  <MenuItem
                    as={ChakraLink}
                    href={urls["international:international_auth:login_page"]()}
                    _hover={{ textDecoration: "none" }}
                  >
                    {t("login")}
                  </MenuItem>
                )}
              </MenuList>
            </Menu>
          </>
        )}
      </HStack>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent dir={dir}>
          <AlertDialogHeader> {t("logout")}</AlertDialogHeader>

          <AlertDialogBody>{t("youAreLoggingOut")}</AlertDialogBody>
          <AlertDialogFooter as={HStack}>
            <Button ref={cancelRef} onClick={onClose}>
              {t("cancel")}
            </Button>
            <Button colorScheme="red" ml={3} onClick={logoutHandler} isLoading={isLoading}>
              {t("yesLogout")}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};
